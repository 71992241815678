import React, { useState, useEffect } from 'react';
import axios from 'axios';

const SearchForm = () => {
  const [query, setQuery] = useState('');
  const [results, setResults] = useState([]);
  const [isValidCPF, setIsValidCPF] = useState(false); // Para validar el formato de CPF
  const [isLoading, setIsLoading] = useState(false); // Para mostrar "Buscando información"
  const [searchCompleted, setSearchCompleted] = useState(false); // Para controlar cuando se ha realizado una búsqueda

  // Función para formatear la entrada como CPF (###.###.###-##)
  const formatCPF = (value) => {
    // Eliminar todos los caracteres que no sean números
    const cleanValue = value.replace(/\D/g, '');
    
    // Aplicar formato de CPF
    if (cleanValue.length <= 11) {
      return cleanValue
        .replace(/(\d{3})(\d)/, '$1.$2')
        .replace(/(\d{3})(\d)/, '$1.$2')
        .replace(/(\d{3})(\d{1,2})$/, '$1-$2');
    }
    
    return cleanValue;
  };

  // Validar que el CPF tiene el formato correcto
  const validateCPF = (cpf) => {
    const cpfPattern = /^\d{3}\.\d{3}\.\d{3}-\d{2}$/;
    return cpfPattern.test(cpf);
  };

  // Manejar cambios en el input
  const handleInputChange = (event) => {
    const inputValue = event.target.value;
    const formattedCPF = formatCPF(inputValue);
    setQuery(formattedCPF);

    // Verificar si el CPF es válido para habilitar el botón de búsqueda
    setIsValidCPF(validateCPF(formattedCPF));
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    setIsLoading(true);
    setSearchCompleted(false);

    try {
      // Enviar la consulta al backend PHP
      const response = await axios.post('https://n16vde82wifo.codeggy.com/api/search.php', {
        query: query.replace(/\D/g, ''), // Enviar solo los números del CPF
      });

      const data = response.data;

      setResults(data);
    } catch (error) {
      setResults([]); // Si hay un error, resetear los resultados
    } finally {
      setIsLoading(false);
      setSearchCompleted(true);
    }
  };

  return (
    <div className="flex justify-center items-center min-h-screen bg-gray-100">
      <div className="bg-white p-8 rounded shadow-md w-full max-w-md">
        <h1 className="text-2xl font-bold mb-6 text-center">Introduzca su CPF <br /> (Sin puntos ni guiones)</h1>
        
        <form onSubmit={handleSubmit} className="flex flex-col">
          <input
            type="text"
            value={query}
            onChange={handleInputChange}
            placeholder="Ejemplo: 111.222.333-44"
            className="mb-4 p-2 border border-gray-300 rounded focus:outline-none focus:ring-2 focus:ring-blue-500"
            maxLength={14} // Limitar el tamaño de la entrada al formato de CPF
            disabled={isLoading} // Deshabilitar el input mientras se busca
          />
          
          <button
            type="submit"
            className={`bg-blue-500 text-white py-2 rounded hover:bg-blue-600 transition duration-200 ${(!isValidCPF || isLoading) && 'opacity-50 cursor-not-allowed'}`}
            disabled={!isValidCPF || isLoading} // Deshabilitar si el CPF no es válido o si está cargando
          >
            {isLoading ? 'Buscando...' : 'Buscar'}
          </button>
        </form>

        <div className="mt-6">
          {isLoading && <p className="text-center text-blue-500">Buscando información...</p>}
          
          {!isLoading && results.length > 0 ? (
            <ul className="space-y-2">
              {results.map((result, index) => (
                <li key={index} className="bg-gray-100 p-2 rounded shadow">
                  {result.remitente}, {result.asunto}, {result.cuerpoMensaje}, {result.fecha}
                </li>
              ))}
            </ul>
          ) : (
            searchCompleted && !isLoading && (
              <p className="text-center text-gray-500">No se encontraron resultados.</p>
            )
          )}
        </div>
      </div>
    </div>
  );
};

export default SearchForm;
